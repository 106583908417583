<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>{{ isGmoOrOfficeLoginStoreAndStore ? '企業情報' : '事務局/企業情報' }}</template>
              <template v-slot:body>
                <div class="form">
                  <section class="form-section">
                    <FormRow :required="true">
                      <template v-slot:label>{{ isGmoOrOfficeLoginStoreAndStore ? '企業名' : '事務局/企業名' }}</template>
                      <template v-slot:labelNote>
                        {{ isGmoOrOfficeLoginStoreAndStore ? '（45文字以内）' : '（20文字以内）' }}
                      </template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <input
                            v-if="isGmoOrOfficeLoginStoreAndStore"
                            v-trim
                            v-maxlength
                            maxlength="45"
                            :class="{ 'form-control': true, 'is-error': errors.company }"
                            type="text"
                            name="name"
                            v-model="form.company"
                          />
                          <input
                            v-if="$permission.isOffice() && !isGmoOrOfficeLoginStoreAndStore"
                            v-trim
                            v-maxlength
                            maxlength="20"
                            :class="{ 'form-control': true, 'is-error': errors.name }"
                            type="text"
                            name="name"
                            v-model="form.name"
                          />
                        </div>
                      </template>
                    </FormRow>
                    <div v-if="!isGmoOrOfficeLoginStoreAndStore">
                      <FormRow :required="true">
                        <template v-slot:label>郵便番号</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <div class="row">
                              <div class="col-3">
                                <input
                                  v-number
                                  v-maxlength
                                  maxlength="7"
                                  :class="{ 'form-control': true, 'is-error': errors.zipcode }"
                                  type="tel"
                                  name="zipcode"
                                  v-model="form.zipcode"
                                  @input="getAddress"
                                />
                              </div>
                            </div>
                          </div>
                        </template>
                      </FormRow>
                      <FormRow :required="true">
                        <template v-slot:label>都道府県</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <div class="row">
                              <div class="col-6">
                                <div class="form-select">
                                  <select
                                    :class="{ 'form-control form-select-input': true, 'is-error': errors.prefectureId }"
                                    name="prefectureId"
                                    v-model="form.prefectureId"
                                  >
                                    <option value="" selected>選択してください</option>
                                    <option
                                      v-for="item in prefectures"
                                      :key="item.id"
                                      :value="item.id"
                                    >{{ item.name }}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </FormRow>
                      <FormRow :required="true">
                        <template v-slot:label>住所</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <div class="form-select">
                              <select
                                :class="{ 'form-control form-select-input': true, 'is-error': errors.municipalityId }"
                                name="municipalityId"
                                v-model="form.municipalityId"
                              >
                                <option value="" selected>市区群町村</option>
                                <option
                                  v-for="item in municipalities"
                                  :key="item.id"
                                  :value="item.id"
                                >{{ item.name }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="form-content-row">
                            <input
                              v-trim
                              v-maxlength
                              maxlength="40"
                              :class="{ 'form-control': true, 'is-error': errors.streetAddress }"
                              type="text"
                              name="streetAddress"
                              placeholder="丁番地"
                              v-model="form.streetAddress"
                            />
                          </div>
                          <div class="form-content-row">
                            <input
                              v-trim
                              v-maxlength
                              maxlength="40"
                              :class="{ 'form-control': true, 'is-error': errors.buildingName }"
                              type="text"
                              name="buildingName"
                              placeholder="建物名・号室"
                              v-model="form.buildingName"
                            />
                          </div>
                        </template>
                      </FormRow>
                      <FormRow :required="true">
                        <template v-slot:label>電話番号</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <div class="row">
                              <div class="col-3">
                                <input
                                  v-number
                                  v-maxlength
                                  maxlength="11"
                                  :class="{ 'form-control': true, 'is-error': errors.telephone }"
                                  type="tel"
                                  name="telephone"
                                  v-model="form.telephone"
                                />
                              </div>
                            </div>
                          </div>
                        </template>
                      </FormRow>
                    </div>
                    <FormRow v-if="isGmoOrOfficeLoginStoreAndStore" :required="true">
                      <template v-slot:label>ジャンル</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="form-select">
                            <select
                              :class="{ 'form-control form-select-input': true, 'is-error': errors.genre }"
                              name="genre"
                              v-model="form.genre"
                            >
                              <option value="">選択してください</option>
                              <option
                                v-for="item in genres"
                                :key="item.id"
                                :value="item.id"
                              >{{ item.name }}</option>
                            </select>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow v-if="isGmoOrOfficeLoginStoreAndStore">
                      <template v-slot:label>カテゴリー</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <SearchSelect
                            singleLabel="name"
                            :isError="errors.categories"
                            :options="eventCategories"
                            :initialValue="form.categories"
                            @change-selection="handleSelectedCategories"
                          />
                        </div>
                      </template>
                    </FormRow>
                  </section>
                  <section v-if="isGmoOrOfficeLoginStoreAndStore" class="form-section">
                    <p class="form-headline">口座情報</p>
                    <FormRow :required="true">
                      <template v-slot:label>金融機関名</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <input
                            v-trim
                            v-maxlength
                            maxlength="20"
                            :class="{ 'form-control': true, 'is-error': errors.bank.bankName }"
                            type="text"
                            name="bankName"
                            placeholder="金融機関名"
                            v-model="form.bank.bankName"
                          />
                        </div>
                        <div class="form-content-row">
                          <div class="row">
                            <div class="col-3">
                              <input
                                v-number
                                v-maxlength
                                maxlength="4"
                                :class="{ 'form-control': true, 'is-error': errors.bank.bankCode }"
                                type="tel"
                                name="bankCode"
                                placeholder="金融機関コード"
                                v-model="form.bank.bankCode"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>支店名</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <input
                            v-trim
                            v-maxlength
                            maxlength="20"
                            :class="{ 'form-control': true, 'is-error': errors.bank.branchName }"
                            type="text"
                            name="branchName"
                            placeholder="支店名"
                            v-model="form.bank.branchName"
                          />
                        </div>
                        <div class="form-content-row">
                          <div class="row">
                            <div class="col-3">
                              <input
                                v-number
                                v-maxlength
                                maxlength="3"
                                :class="{ 'form-control': true, 'is-error': errors.bank.branchCode }"
                                type="tel"
                                name="branchCode"
                                placeholder="支店コード"
                                v-model="form.bank.branchCode"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>口座番号</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="form-group">
                            <div class="form-group-item">
                              <div class="form-select">
                                <select
                                  :class="{ 'form-control form-select-input': true, 'is-error': errors.bank.type }"
                                  name="type"
                                  v-model="form.bank.type"
                                >
                                  <option value="">選択してください</option>
                                  <option v-for="item in bankTypes" :key="item.index" :value="item.value">{{ item.label }}</option>
                                </select>
                              </div>
                            </div>
                            <div class="form-group-item">
                              <input
                                v-number
                                v-maxlength
                                maxlength="7"
                                :class="{ 'form-control': true, 'is-error': errors.bank.number }"
                                type="tel"
                                name="number"
                                v-model="form.bank.number"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>口座名義</template>
                      <template v-slot:labelNote>（カタカナ）</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="row">
                            <div class="col-12">
                              <input
                                v-trim
                                v-maxlength
                                v-katakana
                                v-alphabet
                                maxlength="30"
                                :class="{ 'form-control': true, 'is-error': errors.bank.name }"
                                type="text"
                                name="name"
                                v-model="form.bank.name"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                  </section>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section v-if="isGmoOrOfficeLoginStoreAndStore" class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:body>
                <table class="table">
                  <tbody>
                    <tr v-for="item in tableList" :key="item.index">
                      <template v-if="item.value">
                        <th>{{ item.label }}</th>
                        <td>{{ item.value }}</td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section v-if="isGmoOrOfficeLoginStoreAndStore" class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>利用するイベント</template>
              <template v-slot:body>
                <div class="scrollX">
                  <table class="listTable">
                    <thead>
                      <tr>
                        <th>イベント名</th>
                        <th>グループ</th>
                        <th>支払いサイクル</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="event in selectedEvent" :key="event.index">
                        <td>{{ event.name }}</td>
                        <td>{{ event.groupName }}</td>
                        <td>{{ event.paymentCycle }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item pos-end">
            <ActionButton class="btn btn-main" :handle-submit="edit" button-text="登録する" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import { isPhoneNumber, isBankName, isZipcode, isBankCode, isBranchCode } from '@/helpers/validators';
import nav from '@/mixins/nav/account';
import error from '@/mixins/plugin/error';
import { address } from '@/mixins/plugin/address';
import FormRow from '@/components/FormRow.vue';
import SearchSelect from '@/components/SearchSelect.vue';
import { StatusConstants } from '@/constants/status';

export default {
  name: 'AccountCompany',
  data: function () {
    return {
      pageName: 'アカウント設定',
      form: {},
      prefixs: ['bank'],
      bankTypes: Object.values(StatusConstants.bank),
    };
  },
  computed: {
    ...mapGetters({
      infor: 'auth/infor',
      shopDetail: 'shop/shopDetail',
      genres: 'common/genres',
      eventCategories: 'event/eventCategories',
      isGmoOrOfficeLoginStoreAndStore: 'common/isGmoOrOfficeLoginStoreAndStore',
      commonSubdomain: 'auth/commonSubdomain',
      commonShopId: 'auth/commonShopId',
    }),
    companyAccount() {
      if (this.isGmoOrOfficeLoginStoreAndStore) {
        return this.shopDetail;
      } else {
        return this.infor.company;
      }
    },
    tableList() {
      return [
        { label: '店舗名', value: this.form.name },
        { label: '複数店舗グループ', value: this.form.shopGroup?.name },
        { label: '店舗コード', value: this.form.code },
      ]
    },
    selectedEvent() {
      return this.form.events?.map(item => {
        return {
          name: item.eventName,
          groupName: item.group?.name || '',
          paymentCycle: item.paymentCycle?.name || '',
        }
      })
    },
  },
  components: {
    FormRow,
    SearchSelect
  },
  mixins: [nav, error, address({ formName: 'form' })],
  watch: {
    'form.prefectureId'(val) {
      this.getMunicipalities(val, this.form.municipalityId);
    }
  },
  validations() {
    let form = {
      name: {},
      company: {},
      zipcode: { required, isZipcode },
      prefectureId: { selectRequired: required },
      municipalityId: { selectRequired: required },
      streetAddress: { required, maxLength: maxLength(40) },
      buildingName: { maxLength: maxLength(40) },
      telephone: { required, maxLength: maxLength(11), isPhoneNumber },
    }
    if (this.isGmoOrOfficeLoginStoreAndStore) {
      form = {
        ...form,
        company: { required, minLength: minLength(1), maxLength: maxLength(45) },
        genre: { selectRequired: required },
        bank: {
          bankName: { required, maxLength: maxLength(20) },
          bankCode: { required, maxLength: maxLength(4), isBankCode },
          branchName: { required, maxLength: maxLength(20) },
          branchCode: { required, maxLength: maxLength(3), isBranchCode },
          type: { selectRequired: required },
          number: { required, maxLength: maxLength(7) },
          name: { required, maxLength: maxLength(30), isBankName }
        },
      }
    } else {
      form.name = { required, maxLength: maxLength(20) };
    }
    return { form };
  },
  methods: {
    initForm() {
      this.form = {
        name: '',
        zipcode: '',
        prefectureId: '',
        municipalityId: '',
        streetAddress: '',
        buildingName: '',
        telephone: '',
        genre: {},
        categories: [],
        bank: {
          bankCode: '',
          bankName: '',
          branchCode: '',
          branchName: '',
          type: '',
          number: '',
          name: '',
        },
        shopGroup: {},
        events: [],
      }
    },
    convertForm() {
      this.form = {
        ...this.companyAccount,
        bank: this.companyAccount.bank || this.form.bank,
        genre: this.companyAccount.genre || this.form.genre,
        shopGroup: this.companyAccount.shopGroup || this.form.shopGroup,
      };
    },
    async edit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'accountCompany');
      } else {
        let result;
        if (this.isGmoOrOfficeLoginStoreAndStore) {
          const payload = {
            subdomain: this.commonSubdomain,
            shopId: this.commonShopId,
            ...this.form,
            categories: this.form.categories || [],
            genre: this.form.genre,
            events: this.form.events.map((item) => ({
              directory: item.directory,
              group: item.group?.id || null,
              paymentCycle: item.paymentCycle?.id || null,
            })),
          }
          delete payload?.issueCoupon;
          result = await this.$store.dispatch('shop/updateShop', payload);
        } else {
          result = await this.$store.dispatch('client/updateClient', this.form);
          if (result) this.$store.dispatch('auth/getUserInfor');
        }
        if (result) this.$message.updated('accountCompany');
      }
    },
    handleSelectedCategories(ids) {
      this.form.categories = ids;
    },
    async getDetail() {
      if (this.isGmoOrOfficeLoginStoreAndStore) {
        await this.$store.dispatch('shop/getShopDetail', {
          subdomain: this.commonSubdomain,
          id: this.commonShopId,
        });
      }
    },
  },
  created() {
    this.initForm();
  },
  mounted() {
    const loading = this.$loading.show();
    Promise.all([
      this.$store.dispatch('common/getGenres'),
      this.getDetail(),
    ]).finally(() => {
      this.convertForm();
      this.$loading.clear(loading);
    })
  }
};
</script>
